let cdnjs = {
    "touchswipe": "https://cdnjs.cloudflare.com/ajax/libs/jquery.touchswipe/1.6.18/jquery.touchSwipe.min.js",
    "headroom": "https://cdnjs.cloudflare.com/ajax/libs/headroom/0.9.4/headroom.min.js",
    "lightgallery": "https://cdnjs.cloudflare.com/ajax/libs/lightgallery/1.6.9/js/lightgallery-all.min.js",
    "picturefill": "https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.3/picturefill.min.js",
    "rellax": "https://cdnjs.cloudflare.com/ajax/libs/rellax/1.6.2/rellax.min.js",
    "datepicker_main": "https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/datepicker.min.js",
    "datepicker_lang": "https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/i18n/datepicker.cs.min.js",
    "googlemap": "https://maps.googleapis.com/maps/api/js?key=AIzaSyAz_6SHekcxrOjOebPWcD3A_QXrQaoSGOA",
    "recaptcha": "https://www.google.com/recaptcha/enterprise.js?render={apikey}",
};
