$.fn.lib_reCapcha = function (attr) {
    let elm = $(this);
    if(elm.length) {
        $.getScript(cdnjs.recaptcha.replace("{apikey}",attr)).done(()=> {
            grecaptcha.enterprise.ready(() => {
                grecaptcha.enterprise.execute(attr, {action: 'form'}).then(token => {
                    elm.find(`[name="gtoken"]`).val(token);
                    console.log(elm);
                })
            });
        });
    }
};
